import React, { Component } from 'react';
import { ArrowIcon, SearchIcon } from '../Svg'
import renderHTML from 'react-render-html';
import { Folder } from '../projects/ProjectCard';
import { _getSiteDetails } from "../../config/api/SiteServices"
import { _getProjectDetails } from "../../config/api/ProjectServices";

// loader
import Loader from "../../components/common/Loader"

class Task extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isDocumentView: false,
            stateLoader: true,
            taskCode: ''
        }
    }

    handleDocumentView = () => this.setState({ isDocumentView: !this.state.isDocumentView })

    componentDidMount() {
        this.getTaskDetails()
    }


    getTaskDetails = () => {
        let req = {

        }
        if (this.props.location.pathname.split('/').indexOf("site") == -1) {
            req.projectId = this.props.match.params.id
            req.search = this.state.searchKey
            this.getProjectDetails(req)
        }
        else {
            req.siteId = this.props.match.params.sId
            req.search = this.state.searchKey
            this.getSiteDetails(req)
        }

    }

    // site details
    getSiteDetails = (req) => {
        try {
            _getSiteDetails(req)
                .then(resp => {
                    this.setState({ taskCode: resp.responseData.siteData.taskCode == undefined ? "" : resp.responseData.siteData.taskCode, stateLoader: false })

                })
                .catch(err => {

                })

        }
        catch (err) {

        }
    }

    // project details
    getProjectDetails = (req) => {
        try {
            _getProjectDetails(req)
                .then(resp => {
                    this.setState({ taskCode: resp.responseData.projectData.taskCode == undefined ? "" : resp.responseData.projectData.taskCode , stateLoader: false })

                })
                .catch(err => {

                })

        }
        catch (err) {

        }
    }




    render() {
        let { isDocumentView } = this.state;
        return (
            <div className="row col-sm-12">
                 {this.state.stateLoader &&   <Loader/>}
                <div className="col-sm-12">
                    <div className="tabs_space_padding smartsheet_iframe no_more_parent">
                        {renderHTML(this.state.taskCode)}
                        {!this.state.stateLoader && this.state.taskCode == "" && 
                         <p className="textName no_more_child">
                       No management task is available
                    </p> } 
                    </div>

                   
                </div>
            </div>


        )
    }
}

export default Task;
