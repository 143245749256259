/**
 * @about Project file
 *    in this file show projects
 *    & cards projecct 
 */

import React, { Component } from 'react';
import { ProjectCard } from './SiteCard';
import {connect} from "react-redux"
import {bindActionCreators} from "redux";

// action 
import {getSiteList} from "../../redux/actions/Sites";
// loader
import Loader from "../../components/common/Loader"

import {history} from "../../App";





class Sites extends Component {
   
    componentDidMount(){
        this.props.action.getSiteList({projectId:history.location.pathname.split('/')[2]});
    }

    reDirectTo = (sr)=>{
    
        this.props.history.push(`/projects/${this.props.match.params.id}/site/${sr._id}/map`)
    }


    render() {
        return (
            <div className="body_container">
                 {this.props.isLoading &&   <Loader/>}

                <div className="cm_breadcumd">
                
                </div>

                <div className="projects_card_wrapper">
                    <div className="row no_more_parent">
                        {
                            this.props.sitesData && this.props.sitesData.map((project, index) => (
                                <ProjectCard
                                    key={index}                                    
                                    img = {project.cover}
                                    title = {project.siteName}
                                    // subTitle = {project.address}                                
                                    contant = {project.desc}
                                    onClickProjectCard={() => this.reDirectTo(project)}


                                />

                            ))
                        }
                         {!this.props.isLoading && !this.props.sitesData && <p className="no_more_child">No sites are available.</p>}
                    </div>
                    <br/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state)=>{
    return{
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
        projectsData:state.projects.projectData,
        sitesData:state.sites.siteData

    }
}

const mapDispatchToProps = (dispatch)=>{
    return{
        action:bindActionCreators({getSiteList,}, dispatch)        

    }
}


export default connect(mapStateToProps, mapDispatchToProps) (Sites)

const projectList = [
    {   _id:1,
        img: require('../../assets/img/dumy_project.jpg'),
        title: 'Claymont DE',
        subTitle: 'Multiple Site',
        contant: 'The property consists of the former General Chemical manufacturing facility which was originally developed in 1915, expanded and modernized through 1980 and officially closed in November 2003. The property is '
    },
    {
        _id:2,
        img: require('../../assets/img/dumy_project.jpg'),
        title: 'Claymont DE',
        subTitle: 'Multiple Site',
        contant: 'The property consists of the former General Chemical manufacturing facility which was originally developed in 1915, expanded and modernized through 1980 and officially closed in November 2003. The property is '
    },
    {
        _id:3,
        img: require('../../assets/img/dumy_project.jpg'),
        title: 'Claymont DE',
        subTitle: 'Multiple Site',
        contant: 'The property consists of the former General Chemical manufacturing facility which was originally developed in 1915, expanded and modernized through 1980 and officially closed in November 2003. The property is '
    },
    {
        _id:4,
        img: require('../../assets/img/dumy_project.jpg'),
        title: 'Claymont DE',
        subTitle: 'Multiple Site',
        contant: 'The property consists of the former General Chemical manufacturing facility which was originally developed in 1915, expanded and modernized through 1980 and officially closed in November 2003. The property is '
    },
    {
        _id:5,
        img: require('../../assets/img/dumy_project.jpg'),
        title: 'Claymont DE',
        subTitle: 'Multiple Site',
        contant: 'The property consists of the former General Chemical manufacturing facility which was originally developed in 1915, expanded and modernized through 1980 and officially closed in November 2003. The property is '
    },
    {
        _id:6,
        img: require('../../assets/img/dumy_project.jpg'),
        title: 'Claymont DE',
        subTitle: 'Multiple Site',
        contant: 'The property consists of the former General Chemical manufacturing facility which was originally developed in 1915, expanded and modernized through 1980 and officially closed in November 2003. The property is '
    },
    {
        _id:7,
        img: require('../../assets/img/dumy_project.jpg'),
        title: 'Claymont DE',
        subTitle: 'Multiple Site',
        contant: 'The property consists of the former General Chemical manufacturing facility which was originally developed in 1915, expanded and modernized through 1980 and officially closed in November 2003. The property is '
    },
    {
        _id:8,
        img: require('../../assets/img/dumy_project.jpg'),
        title: 'Claymont DE',
        subTitle: 'Multiple Site',
        contant: 'The property consists of the former General Chemical manufacturing facility which was originally developed in 1915, expanded and modernized through 1980 and officially closed in November 2003. The property is '
    },
]