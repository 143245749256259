/**
 * @About 
 * 
 */

import axios from "axios";
// api end point
import Url from "../env/Urls";

const authKey = "Basic ZHJhd0JyaWRnZV9hZG1pbjphZG1pbg=="



// add portfolio 
export const _addPortFolio = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: Url.USER_ADD_PORTFOLIO,
            data:request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'multipart/form-data',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

} 

export const _getPortFolioAllList = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "GET",
            url: Url.USER_PORTFOLIO_LIST,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}





export const _getPortFolioList = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "GET",
            url: Url.USER_GET_PORTFOLIO,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}


export const _getPortFolioSuggestedList = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "GET",
            url: Url.USER_SUGGESTED_PORTFOLIO_LIST,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken: localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}




