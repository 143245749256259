// react lib
import React, { Component } from 'react';
// react router
import { Link } from 'react-router-dom';
// svg & icons
import { UserIcon, PasswordIcon, AngleIcon } from '../Svg';
import { isEmail } from '../../utility';
import api from "../../config/env/Urls"

// connect to store
import { connect } from "react-redux"

// bind actions
import { bindActionCreators } from "redux"

// actions
import { login } from "../../redux/actions/Auth"

// icons 
import { BackGroundImg, ForGroundImg, LogoImg } from "../common/icon/Icon"
// validation
import {validateEmail} from "../../utils/Validation";

// inline error
import InlineError from "../common/InlineError"

// loader
import Loader from "../../components/common/Loader"



class Login extends Component {

    state = {
        email: '',
        password: '',
        emailError: '',
        passwordError: '',
    }





    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            emailError: '',
            passwordError: ''
        })
    }

    handleValidate = () => {
        let { email, password } = this.state;

    }


    // this function handle on submit login button event or action
    handleSubmit = e => {
       try{
        e.preventDefault();

        const { email, password } = this.state;

        if (email == '')
            this.setState({ emailError: '*Please enter email.' })
         else if(!validateEmail(this.state.email).status)
            {
                this.setState({ emailError: "*Please enter valid email." })
    
    
            }
        if (password == '')
            this.setState({ passwordError: '*Please enter password.' })
          

        if (email != '' && password != '' && validateEmail(this.state.email).status) {
            let req = {
                email: this.state.email,
                password: this.state.password
            }
            this.props.action.login(req)

        }

       }catch(err){

       }
    }

    render() {
        if (localStorage.getItem("accessToken") && localStorage.getItem("isLoggedIn"))
                      this.props.history.push("projects")

        return (
            <div className="auth_body_wrapper" style={{ backgroundImage: `url(${ForGroundImg})` }}>
              {this.props.isLoading &&   <Loader/>}
                <div className="container xl_container">
                    <div className="row h-100">

                        <div className="col-lg-8 order-lg-2 d-flex login_background" style={{ backgroundImage: `url(${BackGroundImg})` }}>
                            <div className="align-self-end auth_content">
                                <h3>
                                    Breaking Barriers<br />
                                    Bridging Gaps<br />
                                    Building Communities
                                </h3>
                                <p>Buyers | Sellers | Communities</p>
                            </div>
                        </div>

                        <div className="col-lg-4 order-lg-1 d-flex">
                            <form className="align-self-center cm_login_form" onSubmit={this.handleSubmit}>
                                <div className="login_logo">
                                    <img src={LogoImg} />
                                </div>

                                <div className="form-group">
                                    <span className="input_icon"><UserIcon /></span>
                                    <input
                                        type="text"
                                        className={`form-control icon_form ${this.state.emailError != '' ? 'is-invalid' : ''}`}
                                        placeholder="Enter email"
                                        name="email"
                                        // value={email}
                                        maxLength={50}
                                        onChange={this.handleChange}
                                    />
                                    <InlineError
                                        message={this.state.emailError}
                                    />
                                </div>

                                <div className="form-group">
                                    <span className="input_icon"><PasswordIcon /></span>
                                    <input
                                        type="password"
                                        className={`form-control icon_form ${this.state.passwordError != '' ? 'is-invalid' : ''}`}
                                        placeholder="Enter password"
                                        name="password"
                                        // value={password}
                                        maxLength={20}
                                        onChange={this.handleChange}
                                    />
                                    <InlineError
                                        message={this.state.passwordError}
                                    />
                                </div>

                                <div className="form-group d-flex justify-content-between">
                                    <Link className="align-self-center forgot_pass" to="/forgot-password">Forgot password?</Link>
                                    <button type="submit" className="btn btn-primary">Login</button>
                                </div>
                               
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators({ login }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);