import React, { Component } from 'react';
import {ArrowIcon, SearchIcon } from '../../Svg';

import { Folder } from '../ProjectCard';

class Gallery extends Component {

    constructor(props){
        super(props);

        this.state = {
            isDocumentView: false
        }
    }

    handleDocumentView = () => this.setState({isDocumentView: !this.state.isDocumentView})

    render(){
        let { isDocumentView } = this.state;
        return(
            <div className="tabs_space_padding">
                <div className="search_wrapper">
                    <div className="min_breadcurm">
                        <h4>
                            <span onClick={this.handleDocumentView}>All</span> 
                            {isDocumentView ? <React.Fragment><span className="arrow_icon"><ArrowIcon /></span> Building Exterior</React.Fragment> : null}
                        </h4>
                    </div>
                    <div className="cm_search">
                        <span className="search_icon"><SearchIcon /></span>
                        <input type="search" className="form-control" placeholder="Search" />
                    </div>
                </div>


                {
                    isDocumentView
                    ? 
                    (
                    <div className="cm_folder_list">
                        {
                            reports.map(item => {
                                return(
                                    <div className="gallery_item">
                                        <img src={require('../../../assets/img/gallery.jpg')} />
                                    </div>
                                )
                            })
                        }
                    </div>
                    )
                :
                (
                    <div className="cm_folder_list">
                        <div className="row">
                            {
                                reports.map(text => <Folder openFolder={this.handleDocumentView} {...text}/>)
                            }
                        </div>
                    </div>
                )
                }

                
            </div>
        )
    }
}

export default Gallery;

const reports = [
    {text: 'Building Exterior'},
    {text: 'Building Interior'},
    {text: 'Surroundings Area'},
    {text: 'Building Exterior'},
    {text: 'Building Interior'},
    {text: 'Surroundings Area'},
    {text: 'Surroundings Area'},
    {text: 'Site Area'}
]