/**
 * @about auth api file that contains login function
 * that get data from servers
 */

import axios from "axios";
// api end point
import Url from "../env/Urls";

const authKey = "Basic ZHJhd0JyaWRnZV9hZG1pbjphZG1pbg=="



// login 
export const _getProjectList = (request) => {
    return new Promise((resolve, reject) => {
        axios({
            method: "GET",
            url: Url.USER_GET_PROJECT_LIST,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

}


// getDetails
export const _getProjectDetails = (request) => {
    return new Promise((resolve, reject) => {
        axios({  
            method: "GET",
            url:`${Url.USER_VIEW_PROJECT}?projectId=${request.projectId}`,
            data:request,
            headers: {
                "Authorization": authKey,
                'Content-Type': 'application/json',
                accessToken:localStorage.getItem("accessToken")
            }
        }).then(resp => {
            if (resp.data.statusCode)
                resolve(resp.data)
            else
                reject(resp.data)

        }).catch(err => reject(err))
    })

} 
 
  




