import React from 'react';

const Overview = () => (
    <div className="tabs_space_padding">
        <h3>Site Background</h3>
        <p>In February 2015, DrawBridge Claymont, LLC (DBC) was contacted by a Braskem America representative in their search for a location for a rail yard. This rail yard will significantly improve their supply chain, create plant efficencies in production, and will enable Braskem to fully utilize their plant capacity.  The site that was chosen was 6300 Philadelphia Pike, in Claymont, DE.  The site is located next to the Sunoco Marcus Hook Industrial Complex, which comprises over 800 acres of land on the Delaware River.  DBC was able to negotiate for the purchase of the site from Chemtrade Solutions, as the site was not for sale.  We set up the acquisition into two separate closings, first the South Parcel and then the North Parcel.   In November 2015, we entered into an Agreement of Sale on the property with Chemtrade.  In March 2016, we executed a 30 year ground lease with Braskem America and on May 13 2016, we received our Final Determination result from the EPA and DNREC. </p>
        <p>Braskem’s rail yard will be built by Lodestar Logistics (www.lodestarlogistics.com), who is a portfolio company of Energy and Minerals Group (www.emgtx.com).  The $12,550,000 railyard is expected to be operational in March 2017.  DBC will then set about development of the balance of the South and North Parcels.</p>

        <h3>Strengths</h3>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

        <h3>Challenges</h3>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
    </div>
)

export default Overview;