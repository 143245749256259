/**
 * @about Project file
 *    in this file show projects
 *    & cards projecct 
 */

import React, { Component } from 'react';
import { ProfolioCard } from './ProfolioCard';
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
// import {getProjectList} from "../../redux/actions/Project";
import { addPortFolio, getPortFolioList, } from '../../redux/actions/PortFolio'

// loader
import Loader from "../../components/common/Loader";
import { Dropdown, Modal, Button, ModalFooter, ModalBody } from 'react-bootstrap';
import InlineError from '../../components/common/InlineError'


import { _getPortFolioSuggestedList, _getPortFolioAllList, _addPortFolio } from "../../config/api/PortfolioService";

import { Select } from 'antd';


const { Option } = Select;

class Projects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            PortfolioModal: false,
            portfolioSite: '',
            portfolioSiteError: '',
            portfolioName: '',
            portfolioNameError: '',
            cover: '',
            coverError:'',
            siteId: []


        }
    }
    handleOnChage = (e) => {
        this.setState({ [e.target.name]: e.target.value, portfolioSiteError: '', portfolioNameError: '', coverError: '' })
    }
    clearFields = () => {
        this.setState({
            portfolioSite: '',
            portfolioSiteError: '',
            portfolioName: '',
            portfolioNameError: '',
            cover: '',
            coverError: '',
            suggestedList: [],
            listData:[]

        })

    }
    closeModal = () => {
        this.setState({
            PortfolioModal: false,
        })
    }
    componentDidMount() {
        this.props.action.getPortFolioList();
        // this.props.action.getPortFolioSuggestedList();

        this.suggestedList()
        this.getAllList()
    }



getAllList = ()=>{
    _getPortFolioAllList()
    .then(resp=> {
        this.setState({listData: resp.responseData.portfolioList})
    })
}






    // get suggested site
    suggestedList = () => {
        _getPortFolioSuggestedList()
            .then(resp => {
                this.setState({ suggestedList: resp.responseData })

            })
            .catch(err => {
            })
    }
    ShowPortfolioModal = () => {
        this.setState({ PortfolioModal: !this.state.PortfolioModal });
    }
    //Uplaod image
    handleOnUpload = (e) => {
        try {
            let file = e.target.files[0]
            this.setState({ cover: file, coverImg: URL.createObjectURL(file) })
        }
        catch (err) {

        }
    }
    
    addPort = (req)=>{
        _addPortFolio(req)
        .then(resp=>{
            this.getAllList()
        })
        .catch(err=> {})
    }

    handlePortFolioSubmit = (e) => {
        try {
            if (this.state.portfolioName == "") {
                this.setState({ portfolioNameError: '*Please enter portfolio name' })
            }
            else if (this.state.cover=="") {
                this.setState({coverError:"*Please enter cover image"})
            }
            // else if  (this.state.portfolioSite = "") {
            //     this.setState({ portfolioSiteError: "*Please Enter portfolio site" })
            // }
            else {

                let formData = new FormData()
                formData.append("portfolioName", this.state.portfolioName);
                formData.append("portfolioSite", this.state.siteId.toString());
                formData.append("cover", this.state.cover);

                if (e.target.name == "save") {
                    this.addPort(formData)

                    this.closeModal()
                    this.clearFields()

                }
                else {

                    this.addPort(formData)
                    // setTimeout(()=> this.getAllList(), 5000)

                    this.closeModal()
                    this.clearFields();
                }
            }


        }
        catch (err) {

        }
    }

    handleOnSelect = (d) => {
        if (this.state.siteId.indexOf(d) == -1) {
            this.setState({ siteId: [...this.state.siteId, d] })

        }
        else if (this.state.siteId.indexOf(d) != -1) {
            this.setState({ siteId: this.state.siteId.filter(item => item != d) })

        }

    }

    render() {
        return (
            <>
                <div className="body_container">
                    {this.props.isLoading && <Loader />}
                    <div className="cm_breadcumd">
                        <p>Portfolio<button class="btn btn-primary float-right" onClick={this.ShowPortfolioModal}>Add New </button></p>
                    </div>
                    <div className="projects_card_wrapper">
                        <div className="row no_more_parent">
                            {
                                this.state.listData && this.state.listData.map((item, index) => (
                                    <ProfolioCard
                                        {...this.props}
                                        key={index}
                                        img={item.cover}
                                        title={item.portfolioName}

                                        // onClickProjectCard={() => this.props.history.push(`/projects/${item._id}`)}
                                    />
                                ))
                            }
                            {!this.props.isLoading && !this.state.listData && <p className="no_more_child">Sorry no more portfolio</p>}
                        </div>
                        <br />
                    </div>
                </div>

                <Modal show={this.state.PortfolioModal} onHide={this.closeModal} className="custom_modal" centered>
                    <Modal.Header closeButton>
                        {/* <Modal.Title> Create New</Modal.Title>  */}
                    </Modal.Header>
                    <Modal.Body>
                        <h6>Create New</h6>
                        <form class="form-horizontal">
                            <div class="form-group row">
                                <label class="control-label col-sm-2" for="email">Name</label><span className="impFields">*</span>
                                <div class="col-sm-10">
                                    <input type="text"
                                        className={`form-control icon_form ${this.state.portfolioNameError != '' ? 'is-invalid' : ''}`}
                                        id="email"
                                        placeholder="Enter Portfolio"
                                        name="portfolioName"
                                        value={this.state.portfolioName}
                                        onChange={this.handleOnChage}
                                    />
                                    <InlineError
                                        message={this.state.portfolioNameError}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="control-label col-sm-2" for="pwd">Cover</label><span className="impFields">*</span>
                                <div class="col-sm-10">
                                    {/* <img src={this.state.cover} />  */}
                                    <input type="file"
                                         className={`form-control icon_form ${this.state.coverError != '' ? 'is-invalid' : ''}`}
                                        id="pwd"
                                        name="cover"
                                        // value={this.state.cover}
                                        onChange={this.handleOnUpload}
                                    />
                                    <InlineError
                                        message={this.state.coverError}
                                    />
                                </div>
                            </div>

                            <div class="form-group row">
                                <label class="control-label col-sm-2" for="pwd">Suggested Sites</label>
                                <div class="col-sm-10">
                                    <Select
                                        mode="multiple"
                                        placeholder="Please select"
                                        // defaultValue={}
                                        value={this.state.siteId}
                                        onChange={this.handleChange}
                                        onSelect={(i) => this.handleOnSelect(i)}
                                        onDeselect={(i) => this.handleOnSelect(i)}
                                    >
                                        {this.state.suggestedList && this.state.suggestedList.map((item, index) => (
                                            <Option value={item._id} key={index}>{`${item.siteName}`}</Option>

                                        ))}
                                    </Select>
                                    <InlineError
                                        message={this.state.portfolioSiteError}
                                    />
                                </div>
                            </div>

                            {/* <div class="form-group">
                                <label class="control-label" >Suggested Sites</label>
                                <div className="suggest_site">
                                    <ul>
                                        {this.state.suggestedList && this.state.suggestedList.map((item, index) => (
                                            <li>Claymont DE</li>

                                        ))
                                        }





                                    </ul>
                                </div>
                            </div> */}
                            <div class="form-group row">
                                <div class="col-sm-offset-2 col-sm-10">
                                    <button type="button" name="save" className="btn btn-primary " onClick={this.handlePortFolioSubmit} >Submit</button>
                                    <button type="button" className="btn btn-default">Cancel</button>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>

                </Modal>
            </>
        )

    }

}

const mapStateToProps = (state) => {
    return {
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
        projectsData: state.portfolio.projectData.projectData,
        // portfolioSuggestedData: state.portfolioSuggested.portfolioSuggestedData

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        action: bindActionCreators({ addPortFolio, getPortFolioList, }, dispatch)

    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Projects)

