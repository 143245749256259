import siteSetting from './index';

const url = siteSetting.api.url;

export default {

    'USER_LOGIN_URL': `${url}user/login`,
    'USER_FORGOT_PASSWORD': `${url}user/forgotPassword`,
    'USER_RESET_PASSWORD': `${url}user/resetPassword`,
    'USER_CHECK_TOKEN': `${url}user/verifyLink`, 
    'USER_GET_PROJECT_LIST': `${url}project/listProjects`,
    'USER_GET_SITE_LIST': `${url}project/listParticularProjectSites`,
    // 'USER_GET_PROFILE': `${url}user/viewProfile`,
    'USER_CHANGE_PASSWORD':`${url}user/changePassword`,
    "USER_VIEW_PROFILE":`${url}user/viewProfile`,
    'USER_EDIT_PROFILE':`${url}user/editProfile`,
    'USER_VIEW_SITE_DETAILS':`${url}site/viewSite`,
    'USER_VIEW_REPORT':`${url}report/getReports`,
    'USER_VIEW_RESOURCE':`${url}resource/getResource`,
    'USER_VIEW_MEDIA':`${url}media/getMedia`,
    'USER_VIEW_PROJECT':`${url}project/viewProject`,
    'USER_SUGGESTED_PORTFOLIO_LIST':`${url}user/getSuggestedSites`,
    'USER_ADD_PORTFOLIO':`${url}user/addPortfolio`,
    'USER_GET_PORTFOLIO':`${url}user/getPortfolioSites` ,
    'USER_PORTFOLIO_LIST':`${url}user/getPortfolioList`,


    


    
    
}
