import React, { Component } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { ArrowIcon } from '../Svg';

// custome imports
import AboutProject from '../projects/smallComponent/AboutProject';
// tab data
import { TabData } from "../projects/smallComponent/Tab";

// notify
import { notify } from "../../components/common/Toaster";


/**
* @about  class ProjectDetails
* this projectDetail class or file containes  map view & tab
*
*/

// roucting lib
import {
    Router,
    Route,
    Switch,
    Redirect,
    Link
} from 'react-router-dom';

import { _getSiteDetails } from "../../config/api/SiteServices"

import MapView from "../map_view/MapView";
import OverView from "../OverView/OverView";
import Reports from "../report/Report";
import Resource from "../resource/Resource";
import Media from "../media/Media";
import Task from "../task/Task";
import Sifter from "../sifter/Sifter";
import Analysis from "../analysis/Analysis";
import MediaView from "../media/MediaView";

// loader
import Loader from "../../components/common/Loader"


class ProjectDetail extends Component {
    constructor(props){
        super(props);
        this.state = {
            stateLoader:true,
            projectName:'',
            projectId:'',
            siteName:'',
            siteData:'',
            activeClass:''
        }
    }


      reDirectTo = (e)=>{
        this.setState({activeClass:e})
        this.props.history.push(e);
    }


    componentDidMount() {
        this.getSiteDetails()
        this.setTab()

    }


    setTab = () => {
        let arr = this.props.location.pathname.split("/") 
        if (arr.indexOf("site") != -1 && arr.indexOf("map") != -1 )
        this.setState({activeClass:"map"})
        else if ( arr.indexOf("site") != -1 && arr.indexOf("reports") != -1)
            this.setState({ activeClass: "reports" })
        else if (arr.indexOf("site") != -1 && arr.indexOf("overview") != -1)
            this.setState({ activeClass: "overview" })
        else if (arr.indexOf("site") != -1 && arr.indexOf("task") != -1)
            this.setState({ activeClass: "task" })
        else if (arr.indexOf("site") != -1 && arr.indexOf("media") != -1)
            this.setState({ activeClass: "media" })      
        else if (arr.indexOf("site") != -1 && arr.indexOf("resources") != -1)
            this.setState({ activeClass: "resources" })
        else if (arr.indexOf("site") != -1 && arr.indexOf("map") != -1)
            this.setState({ activeClass: "map" })
        else if (arr.indexOf("site") != -1 && arr.indexOf("analysis") != -1)
            this.setState({ activeClass: "analysis" })
    }


    getSiteDetails = () => {
        try {
            let req = {
                siteId: this.props.match.params.sId
            }
            _getSiteDetails(req)
                .then(resp => {
                    this.setState({siteData:resp.responseData.siteData ,projectId:resp.responseData.siteData.project._id, siteName:resp.responseData.siteData.siteName,projectName:resp.responseData.siteData.project.projectName, stateLoader: false })

                })
                .catch(err => {
                    if (err.error && err.error.errorCode == 2){
                        notify("err", err.error.message)
                        localStorage.clear()
                        this.props.history.push("/")
                     } 

                })

        }
        catch (err) {

        }
    }


    render() {
        return (
            <div className="cm_body_container">
                 {this.state.stateLoader &&   <Loader/>}

                <div className="cm_breadcumd cm_space">
                <p><Link to="/projects">Projects</Link><span className="arrow_icon"> <ArrowIcon /> </span>  <Link to={`/projects/${this.state.projectId}/site`}> {this.state.projectName} </Link><span className="arrow_icon"><ArrowIcon /></span> {this.state.siteName}</p>
                </div>
                <div className="cm_tabs_wrapper">
                    <AboutProject siteDetails = {this.state.siteData} />

                    <Tabs activeKey={this.state.activeClass} id="noanim-tab-example" onSelect = {(e)=> this.reDirectTo(e)}>
                        {
                            TabData.map((item, index) => (
                               index != 0 && <Tab key={index} eventKey={item.eventKey} title={item.title}>  </Tab>

                            ))
                        }


                    </Tabs>
                    <Switch>
                    <Route exact path="/projects/:id/site/:sId/map" component={MapView} />
                    <Route exact path="/projects/:id/site/:sId/media" component={Media} />
                    <Route exact path="/projects/:id/site/:sId/media/:eId" component={MediaView} />
                    <Route exact path="/projects/:id/site/:sId/reports" component={Reports} />
                    <Route exact path="/projects/:id/site/:sId/resources" component={Resource} />
                    <Route exact path="/projects/:id/site/:sId/overview" component={OverView} />
                    <Route exact path="/projects/:id/site/:sId/sifter" component={Sifter} />
                    <Route exact path="/projects/:id/site/:sId/analysis" component={Analysis} />
                    <Route exact path="/projects/:id/site/:sId/task" component={Task} />
                </Switch>
                </div>
               



            </div>
        )
    }
}

export default ProjectDetail;