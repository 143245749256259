import React, { Component } from 'react';
import { ArrowIcon, SearchIcon } from '../Svg';

import { Folder, ReportItem2 } from '../projects/ProjectCard';

import { _getResource } from "../../config/api/ResourceServices"
// loader
import Loader from "../../components/common/Loader"
import { CopyToClipboard } from 'react-copy-to-clipboard';

class Resource extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isDocumentView: false,
            reports: [],
            stateLoader: true,
            searchKey: '',
        }
    }

    handleDocumentView = () => this.setState({ isDocumentView: !this.state.isDocumentView })

    componentDidMount() {
        this.getReports()
    }


    // getReports
    getReports = () => {
        try {
            let req = {

            }
            if (this.props.location.pathname.split('/').indexOf("site") == -1) {
                req.project = this.props.match.params.id
                req.search = this.state.searchKey
            }
            else {
                req.site = this.props.match.params.sId
                req.search = this.state.searchKey
            }
            _getResource(req)
                .then((resp) => this.setState({ reports: resp.responseData.resourceInfo, stateLoader: false }))
                .catch(err => {

                })

        }
        catch (err) {

        }
    }

    handleOnSerach = (e) => {
        this.setState({ [e.target.name]: e.target.value }, () => this.getReports())
    }

    render() {
        let { isDocumentView } = this.state;
        return (
            <div className="tabs_space_padding">
                {this.state.stateLoader && <Loader />}
                <div className="search_wrapper">

                    <div className="cm_search">
                        <span className="search_icon"><SearchIcon /></span>
                        <input type="search" className="form-control" placeholder="Search"
                            name="searchKey"
                            onChange={this.handleOnSerach}
                        />
                    </div>
                </div>
                {
                    <div className="cm_folder_list">
                        {
                            this.state.reports && this.state.reports.map((item, index) =>
                                <ReportItem2
                                    name={item.resourceName}
                                    desc={item.fileData}
                                    data={item.fileData}
                                    typeData={item.fileType}

                                />

                            )
                        }

                         {!this.state.stateLoader && this.state.reports && this.state.reports.length == 0 && 
                           <div className="no_more_parent">
                         <p className="textName no_more_child">
                         No resources are available.
                    </p>
                    </div> } 


                    </div>

                }





            </div>
        )
    }
}

export default Resource;

const reports = [
    { text: 'Area information' },
    { text: 'Economic Development' },
    { text: 'Financial Incentives/Grants' },
    { text: 'Site Maps & Figures' },
]