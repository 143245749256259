import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { UserIcon, AngleIcon } from '../Svg';
import {validateEmail} from "../../utils/Validation";
import { isEmail } from '../../utility';

import InlineError from "../common/InlineError"

// forgot api service
import { _forgotPassword } from "../../config/api/AuthApi";


// loader
import Loader from "../../components/common/Loader"


// notify
import { notify } from "../../components/common/Toaster";



class ForgotPassword extends Component {
    state = {
        email: '',
        emailError: '',
        isLoading: false,

    }





    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            emailError: ''
        })
    }




    handleValidate = () => {

        let { email } = this.state;


    }



    // on submit send link to user email
    handleSubmit = e => {
        e.preventDefault();
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (this.state.email == '')
            this.setState({ emailError: '*Please enter  email.' })
        else if (!validateEmail(this.state.email).status) {
            this.setState({ emailError: '*Please enter valid email.' })

        }

        else {

            try {
                this.setState({ isLoading: true })
                let req = {
                    email: this.state.email
                }
                _forgotPassword(req)
                    .then(resp => {
                        this.setState({ isLoading: false })
                        this.props.history.push("success", {
                            state: this.state.email
                        })

                    }).catch(err => {
                        this.setState({ isLoading: false })

                        if (err.error)
                            notify("err", err.error.message)
                        else if (err)
                            notify("err", err.message)



                    })

            } catch (err) {
                this.setState({ isLoading: false })
                notify("err", err.message)

            }
        }
    }

    render() {

        let { email } = this.state;

        return (
            <div className="auth_body_wrapper" style={{ backgroundImage: `url(${require('../../assets/img/login_bg.png')})` }}>
                {this.state.isLoading && <Loader />}
                <div className="container xl_container">
                    <div className="row h-100 align-items-center">




                        <div className="col-lg-4"></div>
                        <div className="col-lg-4">
                            <form className="pl-5 pr-5 text-center" onSubmit={this.handleSubmit}>
                                <div className="login_logo mb-5">
                                    <img src={require('../../assets/img/logo.png')} />
                                </div>

                                <h3 className="mb-4">Did you forgot your password?</h3>
                                <p className="mb-5">Enter your email address you’re using for your account below and we will send you a password reset link.</p>

                                <div className="form-group">
                                    <span className="input_icon"><UserIcon /></span>
                                    <input
                                        type="email"
                                        className={`form-control icon_form ${this.state.emailError != '' ? 'is-invalid' : ''}`}
                                        placeholder="Enter email"
                                        name="email"
                                        value={email}
                                        maxLength = {50}
                                        onChange={this.handleChange}
                                    />
                                    <InlineError
                                        message={this.state.emailError}
                                    />
                                </div>


                                <div className="form-group d-flex justify-content-between">
                                    <Link className="align-self-center forgot_pass" to="/login">Back to sign in</Link>
                                    <button type="submit" className="btn btn-primary">Request Reset Link</button>
                                </div>

                                
                            </form>
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}

export default ForgotPassword;