
// react lib
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

// store
import Store from "./redux/store/Store";

// react redux
import { Provider } from "react-redux";

// notification
import { notify } from "./components/common/Toaster"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// bootstrap & css
import 'react-image-gallery/styles/css/image-gallery.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'video-react/dist/video-react.css'; 
import 'antd/dist/antd.css';

import './index.css';


ReactDOM.render(
    <Provider store={Store}>
        <App />
        <ToastContainer />
    </Provider>
    , document.getElementById('root'));
serviceWorker.unregister();
