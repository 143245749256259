import {
    IS_LOADING_START,
    IS_LOADING_STOP,
    GET_SITE_LIST_FAILED,
    GET_SITE_LIST_SUCCESS

} from "./Types";


// notify
import { notify } from "../../components/common/Toaster";

// login api service function
import {_getSiteList} from "../../config/api/SiteServices";



// action for login
export const getSiteList = req => dispatch => {
    try {
         
        dispatch({ type: IS_LOADING_START })

        _getSiteList(req)
            .then(resp => { 
                let payload = {
                    siteData:resp.responseData.siteData
                }       
               
                
                dispatch({ type: GET_SITE_LIST_SUCCESS, payload })
                dispatch({ type: IS_LOADING_STOP })

                // notify("success", resp.responseData.message)

            }).catch(err => {
             dispatch({ type: IS_LOADING_STOP })                       
                  
                if (err.error)
                notify("err", err.error.message)
            else if (err)
                notify("err", err.message)
              


            })

    } catch (error) {
        notify("err", error.message)

    }

}

