import React from 'react';

import Overview from './Overview';
import Reports from './Reports';
import MapView from "../../map_view/MapView"
import Gallery from "./Gallery";
import Resources from "./Resources";

import Sites from "../../sites/Sites";

// icons
import { ArrowIcon, MapViewIcon, OverviewIcon, ReportsIcon, GalleryIcon, ResourcesIcon, SiftingIcon, ManagementIcon, AnalyticsIcon , SitesIcon} from '../../Svg';

const SiteView = () => <><SitesIcon />    Sites </>;
const MapViewText = () => <><MapViewIcon />    Map View</>;
const OverviewText = () => <><OverviewIcon />   Overview</>;
const ReportsText = () => <><ReportsIcon />    Reports</>;
const GalleryText = () => <><GalleryIcon />    Gallery</>;
const ResourcesText = () => <><ResourcesIcon />  Resources</>;
const SiftingText = () => <><SiftingIcon />    Sifting</>;
const ManagementText = () => <><ManagementIcon /> Management</>;
const AnalyticsText = () => <><AnalyticsIcon />  Analytics</>;

export const TabData = [
    {
        eventKey: "site",
        title: <SiteView />,
        component: <Sites />,

    },
    {
        eventKey: "map",
        title: <MapViewText />,
        component: <MapView />,

    },
    {
        eventKey: "overview",
        title: <OverviewText />,
        component: <Overview />,

    },
    {
        eventKey: "reports",
        title: <ReportsText />,
        component: <Reports />,

    },
    {
        eventKey: "media",
        title: <GalleryText />,
        component: <Gallery/>

    },
    {
        eventKey: "resources",
        title: <ResourcesText />,
        component: <Resources/>

    },
    // {
    //     eventKey: "sifter",
    //     title: <SiftingText />,
    //     component: <h1>Sifting</h1>,

    // },
    {
        eventKey: "task",
        title: <ManagementText />,
        component: <h1>Management</h1>,

    },
    {
        eventKey: "analysis",
        title: <AnalyticsText />,
        component: <h1>Analytics</h1>,

    }
]