import {
    IS_LOADING_START,
    IS_LOADING_STOP,
    GET_PORTFOLIO_LIST_SUCCESS,
    GET_PORTFOLIO_LIST_FAILED

} from "./Types";


// notify
import { notify } from "../../components/common/Toaster";

// add and get api service function
import {_addPortFolio, _getPortFolioList } from "../../config/api/PortfolioService";

// action for portfolio
export const addPortFolio = req => dispatch => {
    try {
         
        dispatch({ type: IS_LOADING_START })

        _addPortFolio(req)
            .then(resp => { 
                let payload = {
                    projectData:resp.responseData
                }       
               
                
                dispatch({ type: GET_PORTFOLIO_LIST_SUCCESS, payload })
                dispatch({ type: IS_LOADING_STOP })

                // notify("success", resp.responseData.message)

            }).catch(err => {
             dispatch({ type: IS_LOADING_STOP })                       
                  
            //     if (err.error)
            //     notify("err", err.error.message)
            // else if (err)
            //     notify("err", err.message)
              


            })

    } catch (error) {
        notify("err", error.message)

    }

}



// action for login
export const getPortFolioList = req => dispatch => {
    try {
         
        dispatch({ type: IS_LOADING_START })

        _getPortFolioList(req)
            .then(resp => { 
                let payload = {
                    projectData:resp.responseData
                }       
               
                
                dispatch({ type: GET_PORTFOLIO_LIST_SUCCESS, payload })
                dispatch({ type: IS_LOADING_STOP })

                // notify("success", resp.responseData.message)

            }).catch(err => {
             dispatch({ type: IS_LOADING_STOP })                       
                  
            //     if (err.error)
            //     notify("err", err.error.message)
            // else if (err)
            //     notify("err", err.message)
              


            })

    } catch (error) {
        notify("err", error.message)

    }

}

