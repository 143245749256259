import {
    IS_LOADING_START,
    IS_LOADING_STOP,
    ADD_PORTFOLIO_SUCCESS,
    ADD_PORTFOLIO_FAILED
} from '../actions/Types'

const initalState = {
    projectData: [],


}

// add portfolio
export const portfolio = (state = initalState, action) => {
    switch (action.type) {
        case ADD_PORTFOLIO_SUCCESS:

            return {
                ...state,
                ...action.payload
            }
        case ADD_PORTFOLIO_FAILED:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }

}