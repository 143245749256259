import React from 'react';
import { Link } from 'react-router-dom';

import { FolderIcon } from '../Svg';

export const ProfolioCard = ({img, title, subTitle, contant, onClickProjectCard}) => (
    <div className="col-xl-3 col-lg-4 col-md-6" >
        <div className="project_card profolio_card" onClick = {onClickProjectCard} >
            <div className="project_card_img"><img src={img} /></div>
                <div className="cm_card_padding profolio_card_img">
                    <h3>{title}</h3>
                    <h5>{subTitle}</h5>
                    <p>{contant}</p>
                  
                </div>
        </div>
    </div>
);


export const Folder = ({text, openFolder}) => (
    <div className="col-lg-3 col-md-4 col-sm-6">
        <span onClick={openFolder}>
        <div className="folder_wrap">
            <span className="folder_icon"><FolderIcon /></span>
            <h3>{text}</h3>
        </div>
        </span>
    </div>
);


export const ReportItem = ({text, openFolder}) => (
    <div className="report_item">
        <div className="r_i_pdf"><img src={require('../../assets/img/pdf_img.jpg')} /></div>
        <div className="r_i_content">
            <h4>M_Marcus Hook (PA) Industrial Complex, 2015 Regional Analysis</h4>
            <p>Economic development • Updated Aug 2016</p>
        </div>
        <div className="r_i_download">
            <button className="btn btn-primary db_btn">Download</button>
        </div>
    </div>
)