import React, { Component } from 'react';
import {ArrowIcon, SearchIcon } from '../Svg';

import { Folder, ReportItem } from '../projects/ProjectCard';

class Sifter extends Component {

    constructor(props){
        super(props);

        this.state = {
            isDocumentView: false
        }
    }

    handleDocumentView = () => this.setState({isDocumentView: !this.state.isDocumentView})

    render(){
        let { isDocumentView } = this.state;
        return(
            <div className="tabs_space_padding">
                <h1>come soon...</h1>                
            </div>
        )
    }
}

export default Sifter;

const reports = [
    {text: 'Area information'},
    {text: 'Economic Development'},
    {text: 'Financial Incentives/Grants'},
    {text: 'Site Maps & Figures'},
]