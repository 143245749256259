
import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { CamraIcon } from '../Svg';
//custom lib
import InlineError from '../common/InlineError';
//notify
import { notify } from '../common/Toaster';
// import { _changePassword  } from '../../config/api/AuthApi';

import Loader from "../../components/common/Loader"
//validation
import { validateEmail } from '../../utils/Validation'

// connect to store
import { connect } from "react-redux";
// bind actions
import { bindActionCreators } from "redux";

//actions
import { getUserProfile } from "../../redux/actions/User";



import { _viewUser } from '../../config/api/AuthApi';
import { _editUser } from '../../config/api/AuthApi'





class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isEdit: false,
            firstName: '',
            lastName: '',
            eamil: '',
            address: '',
            phoneNumber: '',
            comName: '',
            profilePic: require('../../assets/img/user.png'),
            stateLoading: true,

            //Error Field
            firstNameError: '',
            lastNameError: '',
            emailError: '',
            addressError: '',
            phoneNumberError: '',
            comNameError: '',
            profilePicError: '',
            showUserName: "",
            userInfo: '',
            proImg: '',
            proImgError: '',
            isChangeIcon: false,
            role: '',
            comName: "",
            sName: '',
            cName: '',
            comName2: '',
            projectCount: 0,
            siteCount: 0

        }
    }
    handlechangeAll = (e) => {
        this.setState({ [e.target.name]: e.target.value, comNameError: '', firstNameError: '', lastNameError: '', emailError: '', addressError: '', phoneNumberError: '', profilePicError: '' })

    }
    componentDidMount() {
        this.getUserDetails()
    }

    // getUser details
    getUserDetails = () => {
        try {

            _viewUser()
                .then(resp => {
                    console.log("datattttttttttttttttttt", resp.responseData.usersInfo)
                    this.setState({
                        address: resp.responseData.usersInfo.profile.address,
                        projectCount: resp.responseData.usersInfo.projectCount == undefined ? 0 : resp.responseData.usersInfo.projectCount,
                        siteCount: resp.responseData.usersInfo.siteCount == undefined ? 0 : resp.responseData.usersInfo.siteCount,
                        role: resp.responseData.usersInfo.profile.role,
                        profilePic: resp.responseData.usersInfo.profile.profilePic,
                        stateLoading: false,
                        firstName: resp.responseData.usersInfo.profile.firstName,
                        sName: `${resp.responseData.usersInfo.profile.firstName} ${resp.responseData.usersInfo.profile.lastName}`,
                        lastName: resp.responseData.usersInfo.profile.role != 3 ? resp.responseData.usersInfo.profile.contactName : resp.responseData.usersInfo.profile.lastName,
                        email: resp.responseData.usersInfo.profile.email,
                        comName: resp.responseData.usersInfo.profile.clientName,
                        cName: resp.responseData.usersInfo.profile.clientName,
                        phoneNumber: resp.responseData.usersInfo.profile.phoneNumber, isLoadingState: false,
                    })

                })
                .catch(err => {
                    console.log("datattttttttttttttttttt", err)

                })

        } catch (error) {

        }
    }

    handleIsEdit = () => this.setState({ isEdit: !this.state.isEdit })
    handelSubmitEdit = (e) => {
        let { firstName, role, lastName, email, address, phoneNumber, comName, } = this.state;

        if (this.state.isChangeIcon && this.state.proImg != "" && this.state.proImg.type.split("/")[0] != "image") {

            this.setState({ proImgError: "*Please add a valid file.", })
        }
        else if (role == 3 && firstName == '') {
            this.setState({ firstNameError: '*please enter first name.' })
        }
        else if (role != 3 && comName == '') {
            this.setState({ comNameError: '*Please enter client name.' })
        }
        else if (role == 3 && lastName == '') {
            this.setState({ lastNameError: '*please enter last name.' })
        }
        else if (role != 3 && lastName == '') {
            this.setState({ lastNameError: '*Please enter client contact name.' })
        }

        else if (phoneNumber == '') {
            this.setState({ phoneNumberError: '*please enter phone number.' })
        }


        else
            this.OnEditUser()
    }

    // on edit submit user
    OnEditUser = () => {
        // let {firstName,lastName,address,phoneNumber,comName,} = this.state
        this.setState({ stateLoading: true })
        try {
            let formData = new FormData()
            formData.append("firstName", this.state.firstName);
            formData.append("lastName", this.state.lastName);
            formData.append("phoneNumber", this.state.phoneNumber);
            formData.append("clientName", this.state.comName);
            formData.append("contactName", this.state.lastName);
            formData.append("profilePic", this.state.proImg)
            formData.append("role", this.state.role)
            formData.append("address", this.state.address)
            _editUser(formData)
                .then(resp => {
                    notify("success", "profile edit successfully")
                    this.props.action.getUserProfile()
                    this.getUserDetails()
                    this.setState({ stateLoading: false, isEdit: false, isChangeIcon: false })
                    // this.props.history.push(`/reports/${this.props.match.params.folderId}`, { state: this.state.folderName });

                }).catch(err => {
                    this.setState({ stateLoading: false })

                    if (err.error)
                        notify("err", err.error.message)
                    else if (err)
                        notify("err", err.message)

                })


        } catch (err) {


        }
    }

    onImageUpload = e => {

        try {
            let file = e.target.files[0]
            this.setState({ proImg: file, proImgError: '', profilePic: URL.createObjectURL(file), isChangeIcon: true })
        }
        catch (err) {

        }
    }


    render() {
        let { sName, cName, address, isEdit, role, firstName, lastName, email, phoneNumber, comName, profilePic, firstNameError, lastNameError, addressError, phoneNumberError, emailError, comNameError, profilePicError } = this.state;

        console.log("roleeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee", role)

        if (role == 3) {
            return (
                <div className="body_container">
                    {this.state.stateLoading && <Loader />}

                    <div className="cm_breadcumd">
                        <p>Profile</p>
                    </div>
                    <div className="profile_wrapper">

                        <div className="header_profile header_profile_big">
                            <div className="profile_img">
                                <span className="header_pro_img header_pro_img_big">
                                    <img src={profilePic} />
                                    {this.state.isEdit && <span className="upload_pro_img upload_pro_img_big">

                                        <input type="file"
                                            ref="fileUploader"
                                            accept="image/png,image/jpeg"
                                            className={`form-control icon_form ${this.state.proImgError != '' ? 'is-invalid' : ''}`}
                                            files={this.state.iconImag}
                                            onChange={this.onImageUpload}
                                        />

                                        <CamraIcon />
                                    </span>}

                                </span>
                                <h4 className="textUpperCase">{sName}</h4>

                                <div className="profile_description">
                                    <h4 className="textUpperCase">{cName}</h4>
                                    {/* <p>43 Street, New Jersy</p> */}
                                    <div className="display_flx">
                                        <div className="profile_project">
                                            <h3>{this.state.projectCount}</h3>
                                            <p>Projects</p>
                                        </div>
                                        <div className="profile_site">
                                            <h3>{this.state.siteCount}</h3>
                                            <p>Sites</p>
                                        </div>
                                    </div>
                                </div>

                            </div>



                            {/* {/ <p><Link to="/">View Profile</Link></p> /} */}
                        </div>



                        <form className="cm_box_view">
                            <InlineError
                                message={this.state.proImgError}
                            />
                            <h4 className="mt-4 mb-4">Account</h4>

                            <div className="form-group row">
                                <label className="col-sm-2 cm_col_2 col-form-label">First name</label>
                                <div className="col-sm-3 cm_col_4">
                                    {
                                        isEdit ? (
                                            <div>
                                                <input
                                                    type="text"
                                                    className={`form-control icon_form ${firstNameError != '' ? 'is-invalid' : ''}`}
                                                    maxLength={30}
                                                    value={firstName}
                                                    placeholder={"First Name"}
                                                    name='firstName'
                                                    onChange={this.handlechangeAll} />
                                                <InlineError
                                                    message={firstNameError}
                                                />
                                            </div>
                                        ) : (
                                                <p>{firstName} </p>
                                            )
                                    }

                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-sm-2 cm_col_2 col-form-label">Last name</label>
                                <div className="col-sm-3 cm_col_4">
                                    {
                                        isEdit ? (
                                            <div>
                                                <input
                                                    type="text"
                                                    className={`form-control icon_form ${lastNameError != '' ? 'is-invalid' : ''}`}
                                                    maxLength={30}
                                                    placeholder={"Last Name"}
                                                    name='lastName'
                                                    value={lastName}
                                                    onChange={this.handlechangeAll}
                                                />
                                                <InlineError
                                                    message={lastNameError}
                                                />
                                            </div>

                                        ) : (
                                                <p>{lastName}</p>
                                            )
                                    }
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-sm-2 cm_col_2 col-form-label">Email</label>
                                <div className="col-sm-3 cm_col_4">
                                    {
                                        isEdit ? (
                                            <div>
                                                <input
                                                    type="text"
                                                    className={`form-control icon_form ${emailError != '' ? 'is-invalid' : ''}`}
                                                    maxLength={30}
                                                    disabled={true}
                                                    name='email'
                                                    value={email}
                                                    onChange={this.handlechangeAll}
                                                />
                                                <InlineError
                                                    message={emailError}
                                                />
                                            </div>
                                        ) : (
                                                <p>{email == undefined ? "" : email}</p>
                                            )
                                    }
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-sm-2 cm_col_2 col-form-label">Phone Number</label>
                                <div className="col-sm-3 cm_col_4">
                                    {
                                        isEdit ? (
                                            <div>
                                                <input
                                                    type="number"
                                                    className={`form-control icon_form ${phoneNumberError != '' ? 'is-invalid' : ''}`}
                                                    maxLength={30}
                                                    name='phoneNumber'
                                                    placeholder={"Phone Number"}
                                                    value={phoneNumber}
                                                    onChange={this.handlechangeAll}
                                                />
                                                <InlineError
                                                    message={phoneNumberError}
                                                />
                                            </div>
                                        ) : (
                                                <p>{phoneNumber == undefined ? '' : phoneNumber}</p>
                                            )
                                    }
                                </div>
                            </div>




                        </form>

                        <div className="profile_btn">
                            {
                                isEdit
                                    ? <button className="btn btn-primary" onClick={this.handelSubmitEdit}>Save</button>
                                    : <button className="btn btn-primary" onClick={this.handleIsEdit}>Edit Profile</button>
                            }

                        </div>
                    </div>
                </div>
            )
        }
        else if (role == 1 || (role == 2)) {
            return (
                <div className="body_container">
                    {this.state.stateLoading && <Loader />}

                    <div className="cm_breadcumd">
                        <p>Profile</p>
                    </div>
                    <div className="profile_wrapper">

                        <div className="header_profile header_profile_big">
                            <div className="profile_img">
                                <span className="header_pro_img header_pro_img_big">
                                    <img src={profilePic} />
                                    {this.state.isEdit && <span className="upload_pro_img upload_pro_img_big">

                                        <input type="file"
                                            ref="fileUploader"
                                            accept="image/png,image/jpeg"
                                            className={`form-control icon_form ${this.state.proImgError != '' ? 'is-invalid' : ''}`}
                                            files={this.state.iconImag}
                                            onChange={this.onImageUpload}
                                        />

                                        <CamraIcon />
                                    </span>}

                                </span>
                            </div>
                            <div className="profile_description">
                                <h4 className="textUpperCase">{cName}</h4>
                                {/* <p>43 Street, New Jersy</p> */}
                                <div className="display_flx">
                                    <div className="profile_project">
                                        <h3>{this.state.projectCount}</h3>
                                        <p>Projects</p>
                                    </div>
                                    <div className="profile_site">
                                        <h3>{this.state.siteCount}</h3>
                                        <p>Sites</p>
                                    </div>
                                </div>
                            </div>

                            {/* {/ <p><Link to="/">View Profile</Link></p> /} */}
                        </div>



                        <form className="cm_box_view">
                            <InlineError
                                message={this.state.proImgError}
                            />
                            <h4 className="mt-4 mb-4">Account</h4>

                            <div className="form-group row">
                                <label className="col-sm-2 cm_col_2 col-form-label">Client Name</label>
                                <div className="col-sm-3 cm_col_4">
                                    {
                                        isEdit ? (
                                            <div>
                                                <input
                                                    type="text"
                                                    className={`form-control icon_form ${comNameError != '' ? 'is-invalid' : ''}`}
                                                    maxLength={30}
                                                    value={comName}
                                                    name='comName'
                                                    placeholder={"Client Name"}
                                                    onChange={this.handlechangeAll} />

                                                <InlineError
                                                    message={comNameError}
                                                />
                                            </div>
                                        ) : (
                                                <p className="textUpperCase" >{comName} </p>
                                            )
                                    }

                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-sm-2 cm_col_2 col-form-label">Contact Name</label>
                                <div className="col-sm-3 cm_col_4">
                                    {
                                        isEdit ? (
                                            <div>
                                                <input
                                                    type="text"
                                                    className={`form-control icon_form ${lastNameError != '' ? 'is-invalid' : ''}`}
                                                    maxLength={30}
                                                    name='lastName'
                                                    value={lastName}
                                                    placeholder={"Contact Name"}
                                                    onChange={this.handlechangeAll}
                                                />
                                                <InlineError
                                                    message={lastNameError}
                                                />
                                            </div>

                                        ) : (
                                                <p>{lastName}</p>
                                            )
                                    }
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-sm-2 cm_col_2 col-form-label">Email</label>
                                <div className="col-sm-3 cm_col_4">
                                    {
                                        isEdit ? (
                                            <div>
                                                <input
                                                    type="text"
                                                    className={`form-control icon_form ${emailError != '' ? 'is-invalid' : ''}`}
                                                    maxLength={30}
                                                    disabled={true}
                                                    name='email'
                                                    value={email}
                                                    onChange={this.handlechangeAll}
                                                />
                                                <InlineError
                                                    message={emailError}
                                                />
                                            </div>
                                        ) : (
                                                <p>{email == undefined ? "" : email}</p>
                                            )
                                    }
                                </div>
                            </div>




                            <div className="form-group row">
                                <label className="col-sm-2 cm_col_2 col-form-label">Phone Number</label>
                                <div className="col-sm-3 cm_col_4">
                                    {
                                        isEdit ? (
                                            <div>
                                                <input
                                                    type="number"
                                                    className={`form-control icon_form ${phoneNumberError != '' ? 'is-invalid' : ''}`}
                                                    maxLength={30}
                                                    name='phoneNumber'
                                                    placeholder={"Phone Number"}
                                                    value={phoneNumber}
                                                    onChange={this.handlechangeAll}
                                                />
                                                <InlineError
                                                    message={phoneNumberError}
                                                />
                                            </div>
                                        ) : (
                                                <p>{phoneNumber == undefined ? '' : phoneNumber}</p>
                                            )
                                    }
                                </div>
                            </div>



                        </form>
                        <br />
                        <div className="profile_btn">
                            {
                                isEdit
                                    ? <button className="btn btn-primary" onClick={this.handelSubmitEdit}>Save</button>
                                    : <button className="btn btn-primary" onClick={this.handleIsEdit}>Edit Profile</button>
                            }

                        </div>
                    </div>
                </div>
            )
        }
        else
            return <></>
    }
}


const mapStateToProps = state => {
    return {
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
        profilePic: state.profile.profilePic,
        userName: state.profile.userName
    }
}

const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators({ getUserProfile }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
