import React, { Component } from 'react';
import { ArrowIcon, SearchIcon } from '../Svg'

import { Folder } from '../projects/ProjectCard';
import { Player, BigPlayButton } from 'video-react';

// api services
import { _getMedia } from "../../config/api/MediaServices";
// loader
import Loader from "../../components/common/Loader";
import { Download, Copy } from '../Svg'
import { CopyToClipboard } from 'react-copy-to-clipboard';
// notify
import { notify } from "../../components/common/Toaster";




let data = [];
class Gallery extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isDocumentView: false,
            medias: '',
            listShow: true,
            index: '',
            stateLoader: true,
        }
    }

    componentDidMount() {
        this.getReports();
    }

    // getReports
    getReports = () => {
        try {
            let req = {

            }
            if (this.props.location.pathname.split('/').indexOf("site") == -1) {
                req.project = this.props.match.params.id
                req.search = this.state.searchKey
            }
            else {
                req.site = this.props.match.params.sId
                req.search = this.state.searchKey
            }

            _getMedia(req)
                .then((resp) => {
                    data.push(...resp.responseData.mediaInfo)
                    this.setState({ medias: resp.responseData.mediaInfo, stateLoader: false, })

                }

                )
                .catch(err => {

                })

        }
        catch (err) {

        }
    }


    handleDocumentView = () => this.setState({ isDocumentView: !this.state.isDocumentView })

    redirectTo = (media, ind) => {
     

        this.setState({ listShow: false, index: ind })

    }


    actionMedia = (action) => {

        if (action == -1) {
            if (this.state.index > 0)
                this.setState({ index: --this.state.index })
        }

        else if (action == 1) {
            if (data.length > this.state.index + 1)
                this.setState({ index: ++this.state.index })
        }

    }


    render() {
        let { isDocumentView, medias } = this.state;
        return (
            <div className="tabs_space_padding">
                {this.state.stateLoader && <Loader />}
                {/* <div className="search_wrapper">
                    <div className="min_breadcurm">
                    </div>
                    <div className="cm_search">
                        <span className="search_icon"><SearchIcon /></span>
                        <input type="search" className="form-control" placeholder="Search" />
                    </div>
                </div> */}
                {
                    this.state.listShow ?
                        <div className="cm_folder_list no_more_parent">
                            {
                                Object.values(medias).map((item, index) => {
                                    return (
                                        <div key={index} className="gallery_item" onClick={() => this.redirectTo(item, index)}>
                                            <img src={item.thumbnail} />
                                        </div>
                                    )
                                })
                            }

                            {!this.state.stateLoader && Object.values(medias).length == 0 && <p className="textName no_more_child">
                                          No media files are available.
                    </p>}
                        </div>
                        :
                    <>
                        <h6><a href="#"><i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;Back</a></h6>
                        <div className="media_parent">
                            {data.length != 0 &&
                                <div className="img_div">

                                    {data && data[this.state.index].mediaType == "I" ?
                                        <img src={data && data[this.state.index].fileData} className="img-fluid" />
                                        :
                                        <Player  >
                                            <source src={data && data[this.state.index].fileData} />
                                            <BigPlayButton position="center" />
                                        </Player>


                                    }
                                    <div className="carousel_btn">

                                        <a class="left carousel-control ">
                                            <i class="fa fa-chevron-left" aria-hidden="true" onClick={() => this.actionMedia(-1)}></i>
                                        </a>
                                        <a class="right carousel-control">
                                            <i class="fa fa-chevron-right" aria-hidden="true" onClick={() => this.actionMedia(1)}></i>
                                        </a>
                                    </div>
                                </div>
                            }
                            {
                                data.length != 0 &&
                                <div className="desc_div">
                                    <p>Name:&nbsp;{data && data[this.state.index].mediaName} </p>
                                    <div className="icon_parent">
                                        <a href={data && data[this.state.index].fileData} download>
                                            <div className="icon">
                                                <Download />
                                                <p>Download</p>
                                            </div>
                                        </a>
                                        
                                            <div className="icon">
                                                <CopyToClipboard text={data && data[this.state.index].fileData}
                                                    onCopy={() => notify("success", "copied")}
                                                >
                                                    <div style = {{cursor:"pointer"}}>
                                                     <Copy />
                                                     <p>copy to clip board</p>
                                                     </div>
                                                </CopyToClipboard>
                                              
                                              
                                            </div>
                                

                                    </div>
                                    <div className="text">
                                        <p>Description:&nbsp;
                                            {
                                                data[this.state.index].desc
                                            }
                                        </p>
                                    </div>
                                </div>
                            }
                        </div>
                    </>
                }







            </div>
        )
    }
}

export default Gallery;

const reports = [
    { text: 'Building Exterior' },
    { text: 'Building Interior' },
    { text: 'Surroundings Area' },
    { text: 'Building Exterior' },
    { text: 'Building Interior' },
    { text: 'Surroundings Area' },
    { text: 'Surroundings Area' },
    { text: 'Site Area' }
]