/**
 * @about Project file
 *    in this file show projects
 *    & cards projecct 
 */

import React, { Component } from 'react';
import { ProjectCard } from './ProjectCard';
import {connect} from "react-redux"
import {bindActionCreators} from "redux";

// action 
import {getProjectList} from "../../redux/actions/Project";
// loader
import Loader from "../../components/common/Loader"



class Projects extends Component {
   
    componentDidMount(){
        this.props.action.getProjectList();
    }


    render() {
        return (
            <div className="body_container">
                 {this.props.isLoading &&   <Loader/>}

                <div className="cm_breadcumd">
                    <p>Projects</p>
                </div>

                <div className="projects_card_wrapper">
                    <div className="row no_more_parent">
                        {
                            this.props.projectsData.map((project, index) => (
                                <ProjectCard
                                   {...this.props}
                                    key={index}                                    
                                    img = {project.cover}
                                    title = {project.projectName}
                                    // subTitle = {project.ype}                                
                                    contant = {project.desc}
                                    onClickProjectCard={() => this.props.history.push(`/projects/${project._id}/site`)}


                                />

                            ))
                        }
                        { this.props.projectsData.length == 0 && !this.props.isLoading && <p className="no_more_child" >No projects are available.</p>}
                    </div>
                    <br/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state)=>{
    return{
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
        projectsData:state.projects.projectData

    }
}

const mapDispatchToProps = (dispatch)=>{
    return{
        action:bindActionCreators({getProjectList}, dispatch)        

    }
}


export default connect(mapStateToProps, mapDispatchToProps) (Projects)

const projectList = [
    {   _id:1,
        img: require('../../assets/img/dumy_project.jpg'),
        title: 'Claymont DE',
        subTitle: 'Multiple Site',
        contant: 'The property consists of the former General Chemical manufacturing facility which was originally developed in 1915, expanded and modernized through 1980 and officially closed in November 2003. The property is '
    },
    {
        _id:2,
        img: require('../../assets/img/dumy_project.jpg'),
        title: 'Claymont DE',
        subTitle: 'Multiple Site',
        contant: 'The property consists of the former General Chemical manufacturing facility which was originally developed in 1915, expanded and modernized through 1980 and officially closed in November 2003. The property is '
    },
    {
        _id:3,
        img: require('../../assets/img/dumy_project.jpg'),
        title: 'Claymont DE',
        subTitle: 'Multiple Site',
        contant: 'The property consists of the former General Chemical manufacturing facility which was originally developed in 1915, expanded and modernized through 1980 and officially closed in November 2003. The property is '
    },
    {
        _id:4,
        img: require('../../assets/img/dumy_project.jpg'),
        title: 'Claymont DE',
        subTitle: 'Multiple Site',
        contant: 'The property consists of the former General Chemical manufacturing facility which was originally developed in 1915, expanded and modernized through 1980 and officially closed in November 2003. The property is '
    },
    {
        _id:5,
        img: require('../../assets/img/dumy_project.jpg'),
        title: 'Claymont DE',
        subTitle: 'Multiple Site',
        contant: 'The property consists of the former General Chemical manufacturing facility which was originally developed in 1915, expanded and modernized through 1980 and officially closed in November 2003. The property is '
    },
    {
        _id:6,
        img: require('../../assets/img/dumy_project.jpg'),
        title: 'Claymont DE',
        subTitle: 'Multiple Site',
        contant: 'The property consists of the former General Chemical manufacturing facility which was originally developed in 1915, expanded and modernized through 1980 and officially closed in November 2003. The property is '
    },
    {
        _id:7,
        img: require('../../assets/img/dumy_project.jpg'),
        title: 'Claymont DE',
        subTitle: 'Multiple Site',
        contant: 'The property consists of the former General Chemical manufacturing facility which was originally developed in 1915, expanded and modernized through 1980 and officially closed in November 2003. The property is '
    },
    {
        _id:8,
        img: require('../../assets/img/dumy_project.jpg'),
        title: 'Claymont DE',
        subTitle: 'Multiple Site',
        contant: 'The property consists of the former General Chemical manufacturing facility which was originally developed in 1915, expanded and modernized through 1980 and officially closed in November 2003. The property is '
    },
]