
/**
* @about  class ProjectDetails
* this projectDetail class or file containes  map view & tab
*
*/

import React, { Component } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { ArrowIcon } from '../Svg';

// custome imports
import AboutProject from './smallComponent/AboutProject';
// tab data
import { TabData } from "./smallComponent/Tab";

import {_getProjectDetails} from "../../config/api/ProjectServices";

// loader
import Loader from "../../components/common/Loader"

// notify
import { notify } from "../../components/common/Toaster";


// roucting lib
import {
    Router,
    Route,
    Switch,
    Redirect,
    Link
} from 'react-router-dom';

import Sites from "../sites/Sites";
import Analysis from "../analysis/Analysis";
import Sifter from "../sifter/Sifter";

import MapView from "../map_view/MapView";
import OverView from "../OverView/OverView";
import Reports from "../report/Report";
import Resource from "../resource/Resource";
import Media from "../media/Media";
import Task from "../task/Task";

import MediaView from "../media/MediaView";




const filterData = TabData.filter((item,index) => index !== 1);

class ProjectDetail extends Component {

    constructor(props){
        super(props);
        this.state = {
            stateLoader:true,
            projectName:'',
            projectId:'',
            siteName:'',
            activeClass:''
        }
    }


    componentDidMount() {
        this.getSiteDetails()
        this.setTab()
    }


    getSiteDetails = () => {
        try {
            let req = {
                projectId:  this.props.match.params.id
            }
            _getProjectDetails(req)
                .then(resp => {
                     this.setState({projectName :resp.responseData.projectData.projectName, stateLoader: false })

                })
                .catch(err => {
                    if (err.error && err.error.errorCode == 2){
                        notify("err", err.error.message)
                        localStorage.clear()
                        this.props.history.push("/")
                     }     
                    
                })

        }
        catch (err) {

        }
    }

    setTab = () => {
        let arr = this.props.location.pathname.split("/")
        if (arr.indexOf("site") != -1)
        this.setState({activeClass:"site"})
        else if (arr.indexOf("reports") != -1)
            this.setState({ activeClass: "reports" })
        else if (arr.indexOf("overview") != -1)
            this.setState({ activeClass: "overview" })
        else if (arr.indexOf("task") != -1)
            this.setState({ activeClass: "task" })
        else if (arr.indexOf("media") != -1)
            this.setState({ activeClass: "media" })      
        else if (arr.indexOf("resources") != -1)
            this.setState({ activeClass: "resources" })
        else if (arr.indexOf("map") != -1)
            this.setState({ activeClass: "map" })
        else if (arr.indexOf("analysis") != -1)
            this.setState({ activeClass: "analysis" })
    }


    reDirectTo = (e)=>{
        this.setState({activeClass:e})
        this.props.history.push(e);
        

    }


    


    render() {
        return (
            <div className="cm_body_container">
                <div className="cm_breadcumd cm_space">
                    <p><Link to="/projects">Projects</Link><span className="arrow_icon"><ArrowIcon /></span> {this.state.projectName}</p>
                </div>

                <div className="cm_tabs_wrapper site_tab">

                    {/* <AboutProject /> */}

                    <Tabs activeKey={this.state.activeClass} id="noanim-tab-example" onSelect = {(e)=> this.reDirectTo(e)} >
                        {
                            filterData.map((item, index) => (
                                <Tab key={index} eventKey={item.eventKey} title={item.title}> </Tab>

                            ))
                        }

                    </Tabs>
                </div>
                <Switch>

                    <Route exact path="/projects/:id/site" component={Sites} />
                    <Route exact path="/projects/:id/sifter" component={Sifter} />
                    <Route exact path="/projects/:id/analysis" component={Analysis} />
                    <Route exact path="/projects/:id/task" component={Task} />
                
                    <Route exact path="/projects/:id/map" component={MapView} />
                    <Route exact path="/projects/:id/media" component={Media} />                    
                    <Route exact path="/projects/:id/reports"  component={Reports} />
                    <Route exact path="/projects/:id/resources" component={Resource} />
                    <Route exact path="/projects/:id/overview" component={OverView} />
                   


                </Switch>



            </div>
        )
    }
}

export default ProjectDetail;