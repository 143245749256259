import {
    IS_LOADING_START,
    IS_LOADING_STOP,
    GET_PROJECT_LIST_FAILED,
    GET_PROJECT_LIST_SUCCESS

} from "./Types";


// notify
import { notify } from "../../components/common/Toaster";

// login api service function
import {_getProjectList} from "../../config/api/ProjectServices";

import {history} from "../../App";




// action for login
export const getProjectList = req => dispatch => {
    try {
         
        dispatch({ type: IS_LOADING_START })

        _getProjectList(req)
            .then(resp => { 
                let payload = {
                    projectData:resp.responseData.projectData
                }       
               
                
                dispatch({ type: GET_PROJECT_LIST_SUCCESS, payload })
                dispatch({ type: IS_LOADING_STOP })

                // notify("success", resp.responseData.message)

            }).catch(err => {
             dispatch({ type: IS_LOADING_STOP })  

             if (err.error && err.error.errorCode == 2){
               localStorage.clear()
                history.push("/")
            }                     
                  
                if (err.error)
                notify("err", err.error.message)
            else if (err)
                notify("err", err.message)

                
              


            })

    } catch (error) {
        notify("err", error.message)

    }

}

