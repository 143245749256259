import {
    GET_USER_PROFILE
} from "../actions/Types";

const initalState = {
    profilePic:'',
    userName:''

}

// get All list users
export const profile = (state = initalState, action) => {
    switch (action.type) {
        case GET_USER_PROFILE:
            return {
                ...state,
                ...action.payload,

            }       

        default:
            return state
    }

}