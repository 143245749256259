import {
    IS_LOADING_START,
    IS_LOADING_STOP,
    IS_LOADER_START,
    IS_LOADER_STOP,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    IS_LOGGED_IN,
    IS_LOGGED_OUT,
    LOG_OUT_SUCCESS,
    LOG_OUT_FAILURE,
    SEARCH_USER_SUCCESS,
    SEARCH_USER_FAILURE
} from "./Types";


// notify
import { notify } from "../../components/common/Toaster";

// login api service function
import {_login} from "../../config/api/AuthApi";



// action for login
export const login = req => dispatch => {
    try {
         
        dispatch({ type: IS_LOADING_START })

        _login(req)
            .then(resp => { 
                let payload = {
                    userProfile:resp.responseData.userProfile
                }       
                localStorage.setItem("accessToken", resp.responseData.accessToken)
                localStorage.setItem("isLoggedIn", "true")
                
                dispatch({ type: LOGIN_SUCCESS, payload })
                dispatch({ type: IS_LOADING_STOP })

                notify("success", resp.responseData.message)

            }).catch(err => {
             dispatch({ type: IS_LOADING_STOP })                       
                  
                if (err.error)
                notify("err", err.error.message)
            else if (err)
                notify("err", err.message)                

                if (err.errorCode == 2)
                dispatch({ type: LOG_OUT_SUCCESS }) 
              


            })

    } catch (error) {
        notify("err", error.message)

    }

}

// log out handle
export const logOut = req => dispatch => {
    dispatch({ type: IS_LOADING_START })
    dispatch({ type: LOG_OUT_SUCCESS })
    dispatch({ type: IS_LOADING_STOP })
}


