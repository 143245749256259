import React from 'react';
import { Link } from 'react-router-dom';
import dateFormat from "dateformat"

// notify
import { notify } from "../../components/common/Toaster";

import { FolderIcon } from '../Svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export const ProjectCard = ({ img, title, subTitle, contant, onClickProjectCard }) =>{ 
    return(
    
    <div className="col-xl-3 col-lg-4 col-md-6" >
        <div className="project_card profolio_card" onClick={onClickProjectCard} >
            <div className="project_card_img"><img src={img} /></div>
            <div className="cm_card_padding profolio_card_img">
                <h3 className ="textUpperCase">{title}</h3>
                <h5>{subTitle}</h5>
                <div className="discription"><p>{contant}</p></div>
                {contant && contant.length >=100 && <div className="readmore"><Link to="#">Read More</Link></div>}
                
               
            </div>
        </div>
    </div>
);
}


export const Folder = ({ text, openFolder }) => (
    <div className="col-lg-3 col-md-4 col-sm-6">
        <span onClick={openFolder}>
            <div className="folder_wrap">
                <span className="folder_icon"><FolderIcon /></span>
                <h3>{text}</h3>
            </div>
        </span>
    </div>
);


export const ReportItem = ({ name, desc, data, }) => (
    <div className="report_item">
        <div className="report_item_half">
            <div className="r_i_pdf"><img src={require('../../assets/img/pdf_img.jpg')} /></div>
            <div className="r_i_content">
                <h4 className ="textUpperCase" >{name}</h4>
                <p>Upload At &nbsp;&nbsp; {dateFormat(desc, "mm/dd/yyyy")}</p>
            </div>
        </div>
        <div className="r_i_download">
            <a href={data} className="btn btn-primary db_btn" download>Download</a>
        </div>
    </div>
)

export const ReportItem2 = ({ name, desc, data, typeData }) => (
    <div className="report_item">
        <div className="report_item_half">
            <div className="r_i_pdf"><img src={require('../../assets/img/pdf_img.jpg')} /></div>
            <div className="r_i_content">
                <h4 className ="textUpperCase" >{name}</h4>
                <p>{desc}</p>
            </div>
        </div>
        {typeData == "url" ? <CopyToClipboard text={data}
         onCopy={() => notify("success", "copied")}
                >
            <button className="btn btn-primary db_btn min-width120">Copy</button>
        </CopyToClipboard> :
            <div className="r_i_download">
                <a href={data} className="btn btn-primary db_btn min-width120" download>Download</a>
            </div>
        }

    </div>
)