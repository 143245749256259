/**
 * @about this file contains action types
 * which type of action fire or which type
 * 
 */

// used for global loading and loader
export const IS_LOADING_START = "IS_LOADING_START";
export const IS_LOADING_STOP = "IS_LOADING_STOP";

// local loading
export const IS_LOADER_START = "IS_LOADER_START"
export const IS_LOADER_STOP = "IS_LOADER_STOP"


// for login
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

// logout
export const IS_LOGGED_IN = "IS_LOGGED_IN";
export const IS_LOGGED_OUT = "IS_LOGGED_OUT"
export const LOG_OUT_SUCCESS = "LOG_OUT_SUCCESS"
export const LOG_OUT_FAILURE = "LOG_OUT_FAILURE"

// get all user list
export const GET_LIST_ALL_USER_SUCCESS = "GET_LIST_ALL_USER_SUCCESS";
export const GET_LIST_ALL_USER_FAILURE = "GET_LIST_ALL_USER_FAILURE";

// delete user
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE"

// search user
export const SEARCH_USER_SUCCESS = "SEARCH_USER_SUCCESS"
export const SEARCH_USER_FAILURE = "SEARCH_USER_FAILURE"

// change status of active or in active
export const CHANGE_USER_STATUS_SUCCESS = "CHANGE_USER_STATUS_SUCCESS"
export const CHANGE_USER_STATUS_ERROR = "CHANGE_USER_STATUS_ERROR"
export const GET_USER_BY_STATUS_SUCCESS = "GET_USER_BY_STATUS_SUCCESS"
export const GET_USER_BY_STATUS_FAILURE = "GET_USER_BY_STATUS_FAILURE"

// get user profile by user
export const GET_USER_PROFILE_BY_ADMIN_SUCCESS = "GET_USER_PROFILE_BY_ADMIN_SUCCESS"
export const GET_USER_PROFILE_BY_ADMIN_FAILURE = "GET_USER_PROFILE_BY_ADMIN_FAILURE"

// log out






// web user


export const GET_PROJECT_LIST_SUCCESS = "GET_PROJECT_LIST_SUCCESS" 
export const GET_PROJECT_LIST_FAILED = "GET_PROJECT_LIST_FAILED"

export const GET_SITE_LIST_SUCCESS = "GET_SITE_LIST_SUCCESS" 
export const GET_SITE_LIST_FAILED = "GET_SITE_LIST_FAILED"

export const ADD_PORTFOLIO_SUCCESS = "ADD_PORTFOLIO_SUCCESS" 
export const ADD_PORTFOLIO_FAILED = "ADD_PORTFOLIO_FAILED"
export const GET_PORTFOLIO_LIST_SUCCESS = "GET_PORTFOLIO_LIST_SUCCESS"
export const GET_PORTFOLIO_LIST_FAILED = "GET_PORTFOLIO_LIST_FAILED"


export const GET_TAG_LIST_FILTER = "GET_TAG_LIST_FILTER" 


export const GET_USER_PROFILE = "GET_USER_PROFILE"
























