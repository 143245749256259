import {combineReducers}from "redux"

// reducers
import {login} from "./Auth";
import {loading} from "./Common";
import {profile} from "./Users";
import {projects} from "./Project";
import {sites} from "./Site";
import {portfolio} from "./PortFolio"

export default combineReducers({
    login,
    loading,
    profile,
    projects,
    sites,
    portfolio
})