import React, { Component } from 'react';
// loader
import Loader from "../../components/common/Loader"

import { _getSiteDetails } from "../../config/api/SiteServices"
import { _getProjectDetails } from "../../config/api/ProjectServices";

class OverView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stateLoader: true,
            isEdit: false,
            mapContent: '',
            desc: '',
            str: '',
            chall: '',
            typeBack:''
        }
    }

    componentDidMount() {
        this.getOverVieww()

    }

    getOverVieww = ()=>{
        let req = {

        }
        if (this.props.location.pathname.split('/').indexOf("site") == -1) {
            req.projectId = this.props.match.params.id         
            this.getProjectDetails(req)
        }
        else {
            req.siteId = this.props.match.params.sId
      
            this.getSiteDetails(req)
        }

    }

    

    getSiteDetails = (req) => {
        try {
            let req = {
                siteId: this.props.match.params.sId
            }
            _getSiteDetails(req)
                .then(resp => {
                    this.setState({typeBack:'Site Background', chall: resp.responseData.siteData.challanges, str: resp.responseData.siteData.strengths, desc: resp.responseData.siteData.desc, stateLoader: false })

                })
                .catch(err => {
                })
        }
        catch (err) {

        }
    }



    // project details
    getProjectDetails = (req) => {
        try {
            _getProjectDetails(req)
                .then(resp => {                 
                    this.setState({typeBack:'Project Background', desc: resp.responseData.projectData.desc, stateLoader: false })

                })
                .catch(err => {

                })

        }
        catch (err) {

        }
    }







    render() {
        return (
            <div className="body_container p-0">
                {this.state.stateLoader && <Loader />}
                <div className="cm_map_wrapper">
                    <div className="tabs_space_padding overview">
                        <h3>{this.state.typeBack}</h3>
                        <p>{this.state.desc}</p>

                        {this.state.str
                            && <>
                                <h3>Strengths</h3>
                                <p>{this.state.str}</p>
                            </>
                        }

                        {
                            this.state.chall && <>
                                <h3>Challenges</h3>
                                <p>{this.state.chall}</p>
                            </>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default OverView;