import React from 'react';
import { Direction , Favorite , Call , Share} from '../../Svg'

const AboutProject = (props) => {
    return(
    <>
    <div className="about_project_wrapper">
        <div className="img_height">
            <img className="img-fluid" src={props.siteDetails.cover} />
        </div>
        {/* <div className="cm_card_padding">
            <h3>Claymont DE 3</h3>
        </div> */}
        <div className="cm_card_padding">
            <h3>{props.siteDetails.siteName}</h3>
            <h5>{ props.siteDetails.address != undefined && props.siteDetails.address && <i class="fa fa-map-marker" aria-hidden="true"></i>}&nbsp;{props.siteDetails.address  != "undefined" && props.siteDetails.address}</h5>
            {/* <p>nbqwfassdzsdsddzssdxsddxdxdxssdsdszsAXZ</p> */}
       </div>
       {  props.siteDetails.lat &&  props.siteDetails.long &&  <div className="icon_set">
           <a className="icon" href = {`https://maps.google.com/?q=${props.siteDetails.lat},${props.siteDetails.long}`}   target = "_blank" >
           

              <Direction />
              <p>Direction</p>
           </a>        
       </div>}

       <div className="description">
           <p>{props.siteDetails.desc}</p>
       </div>       
    </div>
    
   </>
   )
}

export default AboutProject;