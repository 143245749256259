
/**
 * @About
 * 
 * This screen is about change passord
 */

//Lib
import React, { Component } from 'react';

//Custom lib
import InlineError from '../common/InlineError'

// loader
import Loader from "../../components/common/Loader"

// notify
import { notify } from "../../components/common/Toaster";
//
import { _changePassword } from '../../config/api/AuthApi';


// change password screen
class ChangePassword extends Component {

    constructor(props) {
        super(props)
        this.state = {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            isLoader: false,


            //error Field
            oldPasswordError: '',
            newPasswordError: '',
            confirmPasswordError: '',
            change_password_res: '',
            error: '',
            oldPasswordError: '',
            newPasswordError: '',
            confirmPasswordError: '',
        }
    }

    // on change method
    handleChaneAll = (e) => {
        this.setState({ [e.target.name]: e.target.value, oldPasswordError: '', newPasswordError: '', confirmPasswordError: '' })
    }
    //on Submit method
    handelChangePassword = (e) => {
        let { oldPassword, newPassword, confirmPassword } = this.state;

        if (oldPassword == '') {
            this.setState({ oldPasswordError: '*please enter old password.' })
        }
        else if (newPassword == '') {
            this.setState({ newPasswordError: '*Please enter new password.' })
        }
        else if (confirmPassword == '') {
            this.setState({ confirmPasswordError: '*Please enter confirm password.' })
        }
        else if (newPassword == oldPassword) {
            this.setState({ newPasswordError: 'New and old password can not be same.' })
        }
        else if (newPassword.length < 5) {
            this.setState({ newPasswordError: ' Password must be  6 or 6 characters long.' })
        }
        else if (newPassword !== confirmPassword) {
            this.setState({ confirmPasswordError: "New password and confirm password doesn't match." })
            // alert('newPassword and confirm password does not match')
        }
        else {
            try {
                this.setState({ isLoader: true })
                let req = {
                    oldPass: oldPassword,
                    password: newPassword
                }
                _changePassword(req)
                    .then(resp => {
                        this.setState({
                            isLoader: false,                           
                        })
                        if (resp.responseData.message == "Old password does not match.") {
                            notify("err", resp.responseData.message)
                            this.setState({ isLoader: false })
                    
                        }
                        else {
                            notify("success", resp.responseData.message)
                            this.props.history.push("/projects");
                           }
                        
                    }).catch(err => {
                        this.setState({ isLoader: false })

                        if (err.error)
                            notify("err", err.error.message)
                        else if (err)
                            notify("err", err.message)

                    })

            } catch (err) {
                this.setState({ isLoader: false })
                notify("err", err.message)

            }

        }
    }

    render() {
        let { oldPassword, newPassword, confirmPassword, confirmPasswordError, oldPasswordError, newPasswordError } = this.state;
        return (

            <div className="body_container">
                {this.state.isLoader && <Loader />}
                <div className="cm_breadcumd">
                    <p>Change Password</p>
                </div>
                <div className="change_password">
                    <form className="cm_box_view">
                        <div className="form-group row">
                            <label className="col-sm-2 cm_col_2 col-form-label">Old Password</label>
                            <div className="col-sm-3 cm_col_4">
                                <input type="password"
                                    className={`form-control icon_form ${oldPasswordError != '' ? 'is-invalid' : ''}`}
                                    placeholder="Old password"
                                    maxLength={30}
                                    name="oldPassword"
                                    value={oldPassword}
                                    onChange={this.handleChaneAll}
                                />
                                <InlineError
                                    message={oldPasswordError}
                                />


                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-2 cm_col_2 col-form-label">New Password</label>
                            <div className="col-sm-3 cm_col_4">
                                <input type="password"
                                    className={`form-control icon_form ${newPasswordError != '' ? 'is-invalid' : ''}`}
                                    placeholder="New password "
                                    maxLength={30}
                                    name="newPassword"
                                    value={newPassword}
                                    onChange={this.handleChaneAll}
                                />
                                <InlineError
                                    message={newPasswordError}

                                />
                                <InlineError
                                    message={this.state.error}
                                />


                            </div>

                        </div>

                        <div className="form-group row">
                            <label className="col-sm-2 cm_col_2 col-form-label">Confirm Password</label>
                            <div className="col-sm-3 cm_col_4">
                                <input type="password"
                                    className={`form-control icon_form ${confirmPasswordError != '' ? 'is-invalid' : ''}`}
                                    placeholder="Confirm password"
                                    maxLength={30}
                                    name="confirmPassword"
                                    value={confirmPassword}
                                    onChange={this.handleChaneAll}
                                />
                                <InlineError
                                    message={confirmPasswordError}
                                />
                            </div>
                        </div>
                        <br />
                        <button type="button" className="btn btn-primary" onClick={this.handelChangePassword}>Save</button>
                    </form>
                </div>
            </div>
        )
    }
}

export default ChangePassword;


















