import {
    IS_LOADING_START,
    IS_LOADING_STOP,
   GET_PROJECT_LIST_FAILED,
   GET_PROJECT_LIST_SUCCESS
} from "../actions/Types";

const initalState = {
   projectData:[],


}

// login
export const projects = (state = initalState, action) => {
    switch (action.type) {
        case GET_PROJECT_LIST_SUCCESS:
        
            return {
             ...state,
             ...action.payload
            }
        default:
            return state
    }

}