import React, { Component } from 'react';

import renderHTML from 'react-render-html';

// loader
import Loader from "../../components/common/Loader"

import { _getSiteDetails } from "../../config/api/SiteServices"
import { _getProjectDetails } from "../../config/api/ProjectServices";

class MapView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isEdit: false,
            mapContent: '',
            stateLoader:true,
        }
    }

    componentDidMount() {
        this.getMapView()

    }


    getMapView = () => {
        let req = {

        }
        if (this.props.location.pathname.split('/').indexOf("site") == -1) {
            req.projectId = this.props.match.params.id
            req.search = this.state.searchKey
            this.getProjectDetails(req)
        }
        else {
            req.siteId = this.props.match.params.sId
            req.search = this.state.searchKey
            this.getSiteDetails(req)
        }


    }


    // get site details
    getSiteDetails = (req) => {
        try {

            _getSiteDetails(req)
                .then(resp => {
                    this.setState({ mapContent: resp.responseData.siteData.code,stateLoader:false })

                })
                .catch(err => {

                })

        }
        catch (err) {

        }
    }


    // project details
    getProjectDetails = (req) => {
        try {
            _getProjectDetails(req)
                .then(resp => {
                    this.setState({ mapContent: resp.responseData.projectData.code, stateLoader: false })

                })
                .catch(err => {

                })

        }
        catch (err) {

        }
    }

    render() {
        return (
            <div className="body_container p-0">
                 {this.state.stateLoader && <Loader />}
                <div className="cm_map_wrapper">
                    <div className='app '>
                        {renderHTML(this.state.mapContent)}
                        {!this.state.stateLoader && this.state.mapContent == "" && 
                        <div className="no_more_parent">
                                <p className="textName no_more_child">
                                No map is available.
                            </p>
                    </div> 
                    } 
                    </div>
                </div>
            </div>
        )
    }
}

export default MapView;