import {
    GET_USER_PROFILE,
    LOG_OUT_SUCCESS,
} from "./Types";

import {_viewUser} from "../../config/api/AuthApi";

import {history} from "../../App";






// action for login
export const getUserProfile = req => dispatch => {
    try {
         _viewUser()
            .then(resp => { 
                let payload = {
                    profilePic:resp.responseData.usersInfo.profile.profilePic,
                    userName:resp.responseData.usersInfo.profile.firstName
                }
                if(resp.responseData.usersInfo.profile.role == 1 || resp.responseData.usersInfo.profile.role == 2  )
                {
                    payload.userName = resp.responseData.usersInfo.profile.clientName

                }    
                   
               
                
                dispatch({ type: GET_USER_PROFILE, payload })
      

                // notify("success", resp.responseData.message)

            }).catch(err => {                  
                if (err.error && err.error.errorCode == 2){
                    dispatch({ type: LOG_OUT_SUCCESS }) 
                    history.push("/")
                }


            })

    } catch (error) {
     

    }

}

